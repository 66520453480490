exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-agents-neogpt-js": () => import("./../../../src/pages/ai-agents/neogpt.js" /* webpackChunkName: "component---src-pages-ai-agents-neogpt-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-bbbee-certificate-js": () => import("./../../../src/pages/bbbee-certificate.js" /* webpackChunkName: "component---src-pages-bbbee-certificate-js" */),
  "component---src-pages-case-studies-case-studies-details-ahs-js": () => import("./../../../src/pages/case-studies/case-studies-details-ahs.js" /* webpackChunkName: "component---src-pages-case-studies-case-studies-details-ahs-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-throughput-improvement-js": () => import("./../../../src/pages/case-studies/throughput-improvement.js" /* webpackChunkName: "component---src-pages-case-studies-throughput-improvement-js" */),
  "component---src-pages-case-studies-zambian-copper-mine-js": () => import("./../../../src/pages/case-studies/zambian-copper-mine.js" /* webpackChunkName: "component---src-pages-case-studies-zambian-copper-mine-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-directors-js": () => import("./../../../src/pages/directors.js" /* webpackChunkName: "component---src-pages-directors-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-other-policies-js": () => import("./../../../src/pages/legal/other-policies.js" /* webpackChunkName: "component---src-pages-legal-other-policies-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-legal-terms-of-use-js": () => import("./../../../src/pages/legal/terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-service-details-api-js": () => import("./../../../src/pages/services/service-details-api.js" /* webpackChunkName: "component---src-pages-services-service-details-api-js" */),
  "component---src-pages-services-service-details-ds-js": () => import("./../../../src/pages/services/service-details-ds.js" /* webpackChunkName: "component---src-pages-services-service-details-ds-js" */),
  "component---src-pages-services-service-details-js": () => import("./../../../src/pages/services/service-details.js" /* webpackChunkName: "component---src-pages-services-service-details-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

